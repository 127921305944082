//ts-nocheck
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { memo, useEffect } from 'react'
import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../../Helpers/AuthFunctions/LogoutHelpers";
import { authActions } from "../../../../store/auth-slice";
import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";
import MenuContainer from "../../../StyledComponents/MenuContainer/StyledMenuContainer";
import { displayToastSuccess } from "../../../../Helpers/toastHelper";
import { useTranslation } from "react-i18next";
import { IoIosNotifications } from "react-icons/io";
import { socket } from '../../../../socket';
import io from 'socket.io-client';
import useGetData from '../../../../Hooks/useGetData';
import { getNotification, getNotificationRead } from '../../../../Services/DataServices';
const BaseUrl = process.env.REACT_APP_API_ENDPOINT;

type Props = {
  
}

type Menu = {
  id: string;
  title: string;
  title_ar: string;
  description: string;
  description_ar: string;
  type: number;
  route: string;
  is_read: boolean;
};

type Notification = {
  current_page: number;
  data: Menu[];
  first_page_url: string;
  from: number;
  last_page?: number;
  last_page_url: string;
  next_page_url: string;
  path: number;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
  counter: number;
}

type MenuItemProps = {
  id: string;
  title: string;
  description: string;
  setDescription: (description: string) => void;
  setTitle: (description: string) => void;
  type: number;
  onOpen: (isOpen: boolean) => void;
  route?: string | undefined;
  is_read: boolean;
};

const MenuItem = ({ title, description, type, onOpen, setDescription, setTitle, route, is_read, id}: MenuItemProps) => {
  // const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    if(type === 3) {
      onOpen(true);
      setDescription(description)
      setTitle(title)
      // setIsOpen(true); // Maintain local state for visual feedback
    } else {
      navigate(`${route}`)
    }
  };

  

  return (
    <>
      <Typography
        sx={{
          transition: "all 0.1s ease-in",
          padding: "2px 12px",
          whiteSpace: "nowrap",
          backgroundColor: is_read ? "#fff" : '#8768ac30',
          "&:hover": {
            backgroundColor: "#8768ac30",
          },
          zIndex: 100,
        }}
        onClick={() => {
          handleOpen()
          getNotificationRead(`user/read_notifications`, id)
        }}
      >
        {title}
      </Typography>
    </>
  );
};


const Notifications = ({}: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [notifications, setNotifications] = useState<Notification>()
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // const [fooEvents, setFooEvents] = useState([]);

  // const socket = useState(io(`${BaseUrl}/user/notifications`))[0];
  // const socket = useState(io(`${BaseUrl}:3001`))[0];
  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       await getNotification(`user/notifications`, currentPage)
  //         .then((res) => {
  //           setNotifications(res?.data?.data)
  //           setHasMore(res?.data?.next_page_url ? true : false);
  //         })
  //         // setNotifications([...notifications, ...data.results]);
  //         // setHasMore(data.has_next_page);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchNotifications();
  // }, [currentPage, socket])

  useEffect(() => {
    function onConnect() {
      console.log('CONNECTED');
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    // function onFooEvent() {
    // console.log('asdasdasdas');
    // }

    const fetchNotifications = async () => {
      try {
        await getNotification(`user/notifications`, currentPage)
          .then((res) => {
            setNotifications(res?.data?.data)
            setHasMore(res?.data?.next_page_url ? true : false);
          })
          
      } catch (error) {
        console.error(error);
      }
    };
    fetchNotifications();

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('incomingNotification', fetchNotifications);
    
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('incomingNotification', fetchNotifications);
    };
  }, [currentPage]);
  
  const handleCloseMenu = () => {
    setShowMenu(false);
  };
  const handleMouseEnter = () => {
    setShowMenu(true);
    
  };
  const handleClick = () => {
    setShowMenu((prevState) => !prevState);
    
  };

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleLoadLess = () => {
    setCurrentPage(currentPage - 1);
  };

  
  console.log('notifications', notifications, hasMore);
  
  
  return (
    <StyledIcon
      sx={{
        backgroundColor: "primary.main",
        "& svg": {
          color: "white",
        },
        "&:hover": {
          backgroundColor: "primary.main",
        },
        position: 'relative'
      }}
      onMouseEnter={handleMouseEnter}
      onBlur={handleCloseMenu}
      onClick={handleClick}
      // onMouseLeave={handleCloseMenu}
    >
      <IoIosNotifications />
      {(notifications?.counter !== 0) && (

        <Box sx={{
          position: 'absolute',
          width: '18px',
          height: '18px',
          top: '-3px',
          right: '-3px',
          borderRadius: '50%',
          // background: '#FFAD0A',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography sx={{
            fontSize: '18px',
            color: '#FFAD0A',
            lineHeight: '18px',
            fontWeight: 'bold'
          }} >

            {notifications?.counter && notifications?.counter > 9 ? '9+' : notifications?.counter}
          </Typography>
        </Box>
      )}
      {showMenu && notifications && (
        <MenuContainer 
          className="menu"
          onBlur={handleCloseMenu}
          sx={{
            gap: "9px",
            minWidth: "140px",
            // maxWidth: '120px'
          }} 
        >
          {(currentPage !== 1) && (
            <Button 
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: "primary.main",
                boxShadow: "0px 3px 22px #0000002E",
                margin: "8px auto",
                width: { mobile: "100px", xl: "260px" },
                height: {
                  mobile: "50px",
                  xl: "70px",
                },
                borderRadius: "12px",
                fontSize: {
                  mobile: "0.8rem",
                  xl: "1rem",
                },
                color: "white",
              }}
              onClick={(e: any) => {
                e.stopPropagation()
                handleLoadLess()
              }}
              >previous </Button>
          )}
          {notifications?.data?.map((item: Menu, index: number) => {
            return (
              <MenuItem
                key={index}
                id={item.id}
                type={item.type}
                title={i18n.language === "en" ? item.title : item.title_ar}
                description={i18n.language === "en" ? item.description : item.description_ar}
                setDescription={(content) => {
                  setContent(content)
                }}
                setTitle={(title) => {
                  setTitle(title)
                }}
                onOpen={(isOpen) => {
                  setModalIsOpen(isOpen);
                }}
                route={item.route}
                is_read={item.is_read}
              />
            );
          })}

          
          {(currentPage !== notifications?.last_page) && (
            <Button 
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: "primary.main",
                boxShadow: "0px 3px 22px #0000002E",
                margin: "8px auto",
                width: { mobile: "100px", xl: "260px" },
                height: {
                  mobile: "50px",
                  xl: "70px",
                },
                borderRadius: "12px",
                fontSize: {
                  mobile: "0.8rem",
                  xl: "1rem",
                },
                color: "white",
              }}
              onClick={(e: any) => {
              e.stopPropagation()
              handleLoadMore()
            }}>Load More</Button>
          )}
        </MenuContainer>
      )}
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="modal-cart-modal"
        aria-describedby="modal-add-to-cart-modal"
      >
        <Box sx={{
          position: "fixed" as "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30vw",
          height: "60vh",
          backgroundColor: "#fff",
          borderRadius: '20px',
          padding: '16px',
        }}>
          <Typography id="modal-modal-description" sx={{textAlign: 'center', textTransform: 'capitalize'}}>
          {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{}}>
            {content}
          </Typography>
          
        </Box>
      </Modal>
    </StyledIcon>
  );
};

const MemoNotifications = memo(Notifications);

export default MemoNotifications;